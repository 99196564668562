<template>
  <!-- 组件内容 -->

  <div class="footer">
    <div class="container footer-holder">
      <div class="txt-holder">
        <!-- <a href="#" class="footer-link">链接</a>
        <a href="#" class="footer-link">链接</a>
        <a href="#" class="footer-link">链接</a> -->
        <a href="#" class="footer-link">Copyright 2023 Microblade Inc.</a>
      </div>
      <!-- <p class="footer-text">ICP</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
  // 组件的其它配置项
  data() {
    return {
      // 数据属性
    };
  },
  methods: {
    // 组件的方法
  },
};
</script>

<style scoped>
.footer-holder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.txt-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* 组件的样式 */
.footer {
   border-top:1px solid rgba(40,40,40,0.8);
  color: white;
  font-size: 14px;
  background: BLACK;
  height: 100%;
}

.footer-text {
  margin-bottom: 5px;
  margin-top: 40px;

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}

.footer-link {
  text-decoration: none;
  margin-left: 30px;
}

a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color:white;
}
</style>
