<template>
  <div class="header container">
    <div class="logo">
      <!-- 可以在这里替换你的logo，如果被注释掉，布局不受影响 -->
      <!-- <img src="path-to-your-logo.png" alt="Logo" /> -->
      <h3>MicoBlade</h3>
    </div>

    <div class="header-menu-desktop">
      <el-button @click="tryit" type="custom-header">登陆</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    tryit() {
          location.href="https://www.microblade.com.cn/demo/chatweb/"
    },
  },
};
</script>

<style>
.el-header {
  border-bottom: 1px solid #47a992;
}
.el-button--custom-header {
  width: 120px;
  height: 40px;
  background: #47a992 !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 10px !important;
}
.el-menu::after,
.el-menu::before {
  display: block !important;
  content: none !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0px !important;
}
</style>
<style scoped>
.drawer-menu {
  z-index: 2000;
}
.header {
  top: 0;
  left: 0;
  right: 0;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  z-index: 1000;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
}
</style>
