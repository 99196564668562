<template>
  <div id="app">
    <el-header height="80px"> <HeaderComponent /></el-header>
    <el-main> <router-view /></el-main>
    <el-footer height="150px"><FooterComponent></FooterComponent></el-footer>
  </div>
</template>

<script>
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style>
#app {
  font-family: Supreme, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans',
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", 'Segoe UI Symbol',
    "Noto Color Emoji";
  text-align: center;
}
html,
body,
.el-main,
.el-footer {
  margin: 0;
  padding: 0 !important;
}
.el-main{
      overflow: hidden !important;
}
.container {
  max-width: 1024px;
  margin: auto;
}




</style>
